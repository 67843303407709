// @flow

import { VERSION_MAJOR, VERSION_MINOR } from './env';

export const ACCESS_GRANTED = '/access-granted';
export const ACCOUNT_NOTIFICATIONS = '/account/notifications';
export const ACCOUNT_PROFILE = '/account/profile';
export const ACCOUNT_SETTINGS = '/account/settings';
export const ACCOUNT_USER_TOKENS = '/account/access-tokens';
export const ACTION_MANAGER = 'action-manager';
export const ADD = '/add';
export const ADMIN_TEAM_CENTER = '/admin';
export const APP = '/app';
export const APP_DISCOVER = '/actions/discover';
export const AUDIT_LOGS = '/audit-logs';
export const AUTH = '/auth';
export const AUTH_EMAIL = '/auth/email';
export const AUTH_REQUEST_INVITE = '/auth/request-invite';
export const BUILD = '/build';
export const COMMUNITY = '/community';
export const CONDITION = '/condition';
export const CONNECT = '/connect';
export const CUSTOM_INTEGRATION = '/custom-integration';
export const CYPRESS = '/__/'; // Only used for regression tests
export const DASH = '/-';
export const DASHBOARD = '/dashboard';
export const DATATABLE = '/data/tables'; // Data Table path needs to be joined with an id, `/data/tables/:id`
export const DATATABLES = '/data';
export const DEPENDENCY = '/dependency';
export const EDIT = '/edit';
export const EMAIL = '/email';
export const EMAIL_CONFIRM = '/email-confirm';
export const EMAIL_CONFIRMATION = '/email-confirmation';
export const EMAIL_SETTINGS = '/settings/email';
export const EMBED = '/embed';
export const FAVORITES = '/favorites';
export const FIELD = '/field';
export const FIELDS = '/fields';
export const FIND_TEAMS = '/find-teams';
export const FLOWCHART = '/flow';
export const FORM = '/form';
export const FORMS = '/forms';
export const GROUPS = '/groups';
export const HELP = '/help';
export const HOME = '/home';
export const INSIGHTS = '/insights';
export const INSTANCES = '/instances';
export const INTEGRATIONS = '/integrations';
export const INTEGRATIONS_V2 = '/integrations-v2';
export const INVALID = '/invalid';
export const JOIN = '/join';
export const LIBRARY = '/library';
export const LOGIN = '/login';
export const LOGOUT = `${AUTH}/logout`;
export const MESSAGE_CUSTOMIZATION = '/message';
export const MODELS = '/models';
export const NEW = '/new';
export const NON_OAUTH = '/non-oauth';
export const NOTIFICATIONS = '/notifications';
export const OAUTH = '/oauth';
export const PREDICTIVE_MODELS = '/predictive-models';
export const PROCESS = '/process';
export const PROCESSES = '/processes';
export const PROFILE = '/profile';
export const PUSHBOTS = '/pushbots';
export const RELEASE = `/help/docs/release-v${VERSION_MAJOR}.${VERSION_MINOR}/`;
export const REQUEST_INVITE = '/request-invite';
export const RISK = '/risk';
export const ROOT = '/';
export const RUNS = '/runs';
export const SECURITY = '/security';
export const SETTINGS = '/settings';
export const SHARE = '/share';
export const SSO_SETTINGS = '/settings/sso';
export const START = '/start';
export const STEPS = '/steps';
export const SUDO = '/sudo';
export const TABLES = '/tables';
export const TASKS = '/tasks';
export const TASKS_V2 = '/tasks-v2';
export const TEAM = '/team';
export const TEAM_SETTINGS = '/settings';
export const TEST = '/test';
export const TRIGGERS = '/triggers';
export const TRIGGERS_V2 = '/triggers-v2';
export const USER_TOKEN_APPROVAL = `/access-tokens/approve`;
export const USER_TOKEN_CREATION = `${ACCOUNT_USER_TOKENS}/new`;
export const USERS = '/users';
export const VERSIONS = '/versions';
export const WEBFORM = '/webform';
export const WEBFORM_SETTINGS = '/settings/webforms';
export const WORKFLOW_SETTINGS = '/settings/workflows';
export const WEBFORMS = '/webforms';
export const WEBHOOKS = '/webhooks';
export const WORKFLOWS = '/workflows';
export const WORKSPACES = '/workspaces';
export const X = '/x';
