// @flow

import React, { type ComponentType, type Node } from 'react';
import { withProps } from 'recompose';
import { createBrowserHistory as createHistory } from 'history';
import {
  format,
  parse // eslint-disable-line node/no-deprecated-api
} from 'url';
import { Router } from 'react-router-dom';
import any from 'ramda/src/anyPass';
import path from 'ramda/src/path';

import { COMMUNITY } from '../const/path';

type URL = {
  auth?: string,
  hash?: string,
  host?: string,
  hostname?: string,
  href: string,
  path?: string,
  pathname?: string,
  port?: string,
  protocol?: string,
  query?: {
    [key: string]: string
  },
  replace: (url: string) => void,
  search?: string,
  slashes?: boolean
};

type Location = URL | string;

export type History = {
  listen: (listener: Function) => () => void,
  push: (location: Location, state?: Object) => void,
  replace: (location: Location, state?: Object) => void
};

const normalize = (location: Location): URL =>
  typeof location === 'string'
    ? ((parse(location): any): URL)
    : ((location: any): URL);

const createPathMatcher = (pattern: RegExp) => (location: Location) => {
  if (!location) return null;
  const { pathname } = normalize(location);
  return pathname && pattern.test(pathname);
};

const isFalse = () => false;
const env = ((process.env: any): { [string]: string });

export const isEmbeddedPathname = (pattern?: string) =>
  typeof pattern === 'string'
    ? createPathMatcher(new RegExp(pattern))
    : isFalse;

export const isPathname = (url?: string) =>
  typeof url === 'string'
    ? createPathMatcher(new RegExp(`^${url}(/.*)?$`))
    : isFalse;

type FactoryOptions = {
  context?: { location: Location },
  communityUrl?: string,
  embeddedPathname?: string,
  helpUrl?: string
};

export const isExternal = ({
  communityUrl = COMMUNITY,
  embeddedPathname = env.PUSHBOT_EMBEDDED_MODE_PATH_REGEX,
  helpUrl = env.CATALYTIC_HELP_URL
}: FactoryOptions = {}) =>
  any([
    isPathname(communityUrl),
    isEmbeddedPathname(embeddedPathname),
    isPathname(helpUrl)
  ]);

type Factory = (options: Object) => History;

type Options = {
  basename?: string,
  forceRefresh?: boolean,
  getUserConfirmation?: Function,
  keyLength?: number
};

export function withExternalRoutes(
  createHistory: Factory,
  options?: FactoryOptions
): Factory {
  const check = isExternal(options);
  const contextLocation =
    path(['context', 'location'], options) || window.location;
  return function(options: Options): History {
    const history = createHistory(options);

    const historyPush = history.push;
    const historyReplace = history.replace;

    history.push = (location: Location, ...args) => {
      if (!check(location)) {
        return historyPush(location, ...args);
      }
      contextLocation.href = format(normalize(location));
    };

    history.replace = (location: Location, ...args) => {
      if (!check(location)) {
        return historyReplace(location, ...args);
      }

      contextLocation.replace(format(normalize(location)));
    };

    return history;
  };
}

export type Props = Options & {
  history?: History,
  children?: Node
};

function BrowserRouter({ children, history }: Props) {
  // Some of the types defined in this file conflict with types installed for
  // react-router-dom lib flow-typed/npm/react-router-dom_v4.x.x.js
  // $FlowIgnore
  return <Router {...{ children, history }} />;
}

BrowserRouter.displayName = 'BrowserRouter';

const EnhancedBrowserRouter: ComponentType<{ ...Props }> = withProps(
  ({ children, history, ...props }) => ({
    history: withExternalRoutes(createHistory)(props)
  })
)(BrowserRouter);

export default EnhancedBrowserRouter;
