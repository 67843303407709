// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import boolean from 'boolean';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Badge,
  CERULEAN,
  Clamp,
  ExtraSmallText,
  Grid,
  Menu,
  MenuItem,
  NavButton,
  SmallText
} from '@catalytic/catalytic-ui';
import { ReactComponent as UserIcon } from '@catalytic/catalytic-icons/lib/icons/user.svg';
import NavbarLogout from './NavbarLogout';
import {
  COMMUNITY as COMMUNITY_PATH,
  ACCOUNT_PROFILE as ACCOUNT_PROFILE_PATH,
  RELEASE as RELEASE_PATH
} from '../const/path';
import {
  HELP as HELP_URL,
  TERMS_PRIVACY as TERMS_PRIVACY_URL
} from '../const/url';

const useStyles = makeStyles(theme => ({
  thumbnail: {
    borderRadius: '50%',
    height: '2rem',
    width: '2rem'
  },
  icon: {
    fontSize: '2rem'
  },
  opener: {
    marginLeft: '.5rem'
  },
  header: {
    paddingTop: '1rem',
    paddingRight: theme.functions.toRem(theme.variables.menuItemPaddingRight),
    paddingBottom: '1rem',
    paddingLeft: theme.functions.toRem(theme.variables.menuItemPaddingLeft),
    minWidth: '15rem', // 240px
    textAlign: 'left'
  },
  fullName: {
    display: 'block',
    fontWeight: 500
  },
  email: {
    display: 'block',
    color: theme.colors.battleshipGrey
  },
  badge: {
    marginLeft: '1rem'
  },
  divider: {
    borderTop: `1px solid ${theme.colors.lightGrey}`
  }
}));

type Props = {
  email?: string,
  fullName?: string,
  isTeamAdmin?: boolean,
  isTeamOwner?: boolean,
  nullThumbnail?: boolean,
  samlConfigEnabled?: boolean,
  thumbnailUrl?: string
};

export const NavbarUser = ({
  email,
  fullName,
  isTeamAdmin,
  isTeamOwner,
  nullThumbnail,
  samlConfigEnabled,
  thumbnailUrl
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const helpUrl =
    new URL('/', HELP_URL).toString() || 'https://help.catalytic.com/';
  const alt =
    fullName &&
    typeof fullName === 'string' &&
    email &&
    typeof email === 'string'
      ? `${fullName} (${email})`
      : typeof fullName === 'string'
      ? fullName
      : undefined;

  return (
    <Menu
      opener={
        // TODO: Remove `tabIndex` when Menu component supports buttons as openers
        <NavButton tabIndex={-1}>
          {thumbnailUrl && typeof thumbnailUrl === 'string' ? (
            <img className={classes.thumbnail} src={thumbnailUrl} alt={alt} />
          ) : nullThumbnail ? (
            <UserIcon className={classes.icon} />
          ) : null}
        </NavButton>
      }
      openerClassName={classes.opener}
      zIndex={theme.zIndex.nav}
    >
      <Grid className={classes.header} container>
        <Grid item xs zeroMinWidth>
          {typeof fullName === 'string' && (
            <SmallText className={classes.fullName}>
              <Clamp clamp={1}>{fullName}</Clamp>
            </SmallText>
          )}
          {typeof email === 'string' && (
            <ExtraSmallText className={classes.email}>
              <Clamp clamp={1}>{email}</Clamp>
            </ExtraSmallText>
          )}
        </Grid>
        {(isTeamAdmin || isTeamOwner) && (
          <Grid item>
            <Badge className={classes.badge} colorScheme={CERULEAN} compact>
              <FormattedMessage id="navbarUser.admin" defaultMessage="Admin" />
            </Badge>
          </Grid>
        )}
      </Grid>
      <MenuItem className={classes.divider}>
        <Link to={ACCOUNT_PROFILE_PATH}>
          <FormattedMessage
            id="navbarUser.myAccount"
            defaultMessage="My Account"
          />
        </Link>
      </MenuItem>
      {boolean(process.env.CATALYTIC_HIDE_ALL_EXTERNAL_LINKS) ? null : (
        <>
          <MenuItem className={classes.divider}>
            <a href={COMMUNITY_PATH} rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="navbarUser.community"
                defaultMessage="Community"
              />
            </a>
          </MenuItem>
          <MenuItem>
            <a href={helpUrl} rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="navbarUser.help"
                defaultMessage="Help &amp; Support"
              />
            </a>
          </MenuItem>
          <MenuItem>
            <a href={RELEASE_PATH} rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="navbarUser.release"
                defaultMessage="What&lsquo;s New?"
              />
            </a>
          </MenuItem>
        </>
      )}
      {boolean(process.env.CATALYTIC_HIDE_ALL_EXTERNAL_LINKS) ||
      boolean(process.env.CATALYTIC_HIDE_PRIVACY_LINK) ? null : (
        <MenuItem>
          <a href={TERMS_PRIVACY_URL} rel="noopener noreferrer" target="_blank">
            <FormattedMessage
              id="navbarUser.privacy"
              defaultMessage="Privacy"
            />
          </a>
        </MenuItem>
      )}
      {!samlConfigEnabled && (
        <MenuItem className={classes.divider}>
          <NavbarLogout />
        </MenuItem>
      )}
    </Menu>
  );
};
NavbarUser.displayName = 'NavbarUser';

export default NavbarUser;
