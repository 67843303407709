// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import BrowserRouter from './Router/BrowserRouter';
import { IntlProvider } from './Locale/LocaleContext';
import { createAnalytics } from '@catalytic/analytics-client';
import theme from '@catalytic/catalytic-ui/dist/style/theme';
import ThemeProvider from '@catalytic/catalytic-ui/dist/style/ThemeProvider';
import MasterDetailProvider from '@catalytic/catalytic-ui/dist/MasterDetail/MasterDetailProvider';
import WebApp from './WebApp/WebApp';
import Bootloader from './Bootloader/Bootloader';
import registerServiceWorker from './config/registerServiceWorker';
import { AnalyticsProvider } from './Analytics/AnalyticsContext';
import { SessionProvider } from './Session/SessionContext';
import { TeamProvider } from './Team/TeamContext';

const version = process.env.VERSION || 'latest';
const commit = process.env.GIT_COMMIT || '';

// Print the current application version to the console
console.info('Version', version, commit && `(${commit})`);

ReactDOM.render(
  <Bootloader>
    {({ cleanSession, client, isLoggedIn, loading, logout }) =>
      loading ? (
        <div />
      ) : (
        <AnalyticsProvider
          value={{
            analytics: createAnalytics({
              app: 'catalytic-web',
              version,
              catalyticConfig: { apolloClient: client }
            })
          }}
        >
          <SessionProvider value={{ cleanSession, isLoggedIn, logout }}>
            <ApolloProvider client={client}>
              <IntlProvider>
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <MasterDetailProvider>
                      <TeamProvider>
                        <WebApp />
                      </TeamProvider>
                    </MasterDetailProvider>
                  </BrowserRouter>
                </ThemeProvider>
              </IntlProvider>
            </ApolloProvider>
          </SessionProvider>
        </AnalyticsProvider>
      )
    }
  </Bootloader>,
  window.document.getElementById('root')
);
registerServiceWorker();
/**
 * Puts your console on blast when React is making unnecessary updates.
 * https://github.com/welldone-software/why-did-you-render
 *
 * Mark all the components you want to be notified about their re-renders like so:
 * - static whyDidYouRender = true;
 * - Component.whyDidYouRender = true;
 * - setStatic('whyDidYouRender', true)
 */
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    collapseGroups: true,
    trackAllPureComponents: false
  });
}
